<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.55691 1.68733C11.1479 -0.214672 16.4109 1.96633 18.3129 6.55733C20.2149 11.1483 18.0339 16.4113 13.4429 18.3133C8.85191 20.2153 3.58891 18.0343 1.68691 13.4433C-0.214091 8.85233 1.96591 3.58933 6.55691 1.68733"
      stroke="#80869A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.94997 15.1171C10.548 15.1171 10.99 14.6491 10.99 14.0771C10.99 13.5051 10.548 13.0371 9.94997 13.0371C9.35197 13.0371 8.90997 13.5051 8.90997 14.0771C8.90997 14.6491 9.35197 15.1171 9.94997 15.1171ZM9.16997 11.6201C9.16997 12.0509 9.51919 12.4001 9.94997 12.4001C10.3808 12.4001 10.73 12.0509 10.73 11.6201V5.78C10.73 5.34922 10.3808 5 9.94997 5C9.51919 5 9.16997 5.34922 9.16997 5.78V11.6201Z"
      fill="#413DFF"
    />
  </svg>
</template>
